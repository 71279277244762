import React from 'react';
import classNames from 'classnames';

import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className={classNames('level', classes.level)}>
          <div className="level-left">
            <div className="level-item">
              © 2022 <span>&nbsp; Otto Retail Media</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
